/* these styles are included in an extra bundle for anonymous users.
 * build with `yarn build:css`
 * see plone/app/mosaic/profiles/default/registry/resources.xml
 */
.mosaic-grid-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
}

.mosaic-grid-row {
  position: relative;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.mosaic-grid-row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (max-width: 767.98px) {
  .mosaic-grid-row .col {
    flex: 0 0 auto;
  }
  .mosaic-grid-row .col-1,
  .mosaic-grid-row .col-2,
  .mosaic-grid-row .col-3,
  .mosaic-grid-row .col-4,
  .mosaic-grid-row .col-5,
  .mosaic-grid-row .col-6,
  .mosaic-grid-row .col-7,
  .mosaic-grid-row .col-8,
  .mosaic-grid-row .col-9,
  .mosaic-grid-row .col-10,
  .mosaic-grid-row .col-11,
  .mosaic-grid-row .col-12 {
    width: 100%;
  }
}

body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header),
body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) {
  max-width: unset;
  padding-left: unset;
  padding-right: unset;
}
body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) > .row,
body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) > .row {
  margin: 0;
}
body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) > .row #portal-column-content,
body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) > .row #portal-column-content {
  padding: 0;
}
body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row,
body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row,
  body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row {
    max-width: 540px;
  }
  body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background,
  body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background {
    padding-left: calc((100% - 540px) * 0.5);
    padding-right: calc((100% - 540px) * 0.5);
  }
}
@media (min-width: 768px) {
  body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row,
  body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row {
    max-width: 720px;
  }
  body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background,
  body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background {
    padding-left: calc((100% - 720px) * 0.5);
    padding-right: calc((100% - 720px) * 0.5);
  }
}
@media (min-width: 992px) {
  body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row,
  body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row {
    max-width: 960px;
  }
  body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background,
  body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background {
    padding-left: calc((100% - 960px) * 0.5);
    padding-right: calc((100% - 960px) * 0.5);
  }
}
@media (min-width: 1200px) {
  body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row,
  body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row {
    max-width: 1140px;
  }
  body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background,
  body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background {
    padding-left: calc((100% - 1140px) * 0.5);
    padding-right: calc((100% - 1140px) * 0.5);
  }
}
@media (min-width: 1400px) {
  body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row,
  body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row {
    max-width: 1320px;
  }
  body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background,
  body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background {
    padding-left: calc((100% - 1320px) * 0.5);
    padding-right: calc((100% - 1320px) * 0.5);
  }
}
body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid,
body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid {
  max-width: unset;
  padding-left: unset;
  padding-right: unset;
  margin-left: 0;
  margin-right: 0;
}
body.template-layout.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background,
body.mosaic-enabled.no-portlet-columns .outer-wrapper > .container:not(#content-header) .mosaic-grid-row.mosaic-grid-row-fluid-background {
  max-width: unset;
  margin-left: 0;
  margin-right: 0;
}

/* Images will never be bigger then a tile */
.mosaic-tile img {
  max-width: 100%;
  height: auto;
}

/*
  prevent margin-top for first heading element's,
  to have the grid-cells starting at the same horizontal level
*/
.mosaic-tile-content h1:first-child, .mosaic-tile-content h2:first-child, .mosaic-tile-content h3:first-child, .mosaic-tile-content h4:first-child, .mosaic-tile-content h5:first-child, .mosaic-tile-content h6:first-child {
  margin-top: 0;
}

.mosaic-tile-align-center {
  text-align: center;
}

.mosaic-tile-align-left {
  float: left;
  z-index: 200;
}

.mosaic-tile-align-right {
  float: right;
  z-index: 200;
}

.mosaic-tile-padding-right {
  padding-right: 1em;
}

.mosaic-tile-padding-bottom {
  padding-bottom: 1ex;
}

.mosaic-grid-row-dark {
  background: #ccc;
  padding: 1ex 1em;
  color: #2c2c2c;
}
.mosaic-grid-row-dark + .mosaic-grid-row {
  padding-top: 1ex;
}
.mosaic-grid-row-dark + .mosaic-grid-row-dark {
  padding-top: 0;
}

.mosaic-tile #commenting {
  font-size: 100%;
}

.mosaic-tile .comment {
  font-size: 100%;
}

#content .mosaic-tile .discussion div.documentByLine {
  font-size: 100%;
}

/*# sourceMappingURL=mosaic.css.map */
